.homeimage{
    width: 60vw;
    height: 100vh;
    background-image: url('../images/Foto_Nicola_copy.jpeg') ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}



#home{
    height: 100vh; 
    background-color: rgb(0, 0, 0, 0.94);
    display: flex;
    justify-content:center;
    align-items: flex-start;  
    z-index: 1;
    
}

.hometesto{
    margin-left: var(--space-3xl);
    width: 60vw;
    height: 60vh;
    padding-top: var(--space-xl);
    margin-top: 3vw;
}

.home{
    background-image: url('../images/cardboard-flat.png'); /* Immagine con effetto satinato */
    opacity: 1; /* Opacità dell'immagine di sfondo */
    z-index: -1; /* Posiziona dietro il contenuto principale */
    
}

.home h1{
    
    font-size: var(--step-4);
    line-height: var(--space-xl);
    transition: all 0.5s ease-in-out;
    text-align: left;
    margin: 0;
}

.home h2{
    font-size: var(--step-2);
    color: rgb(256, 256, 256, 0.4);
    text-align: justify;
    line-height: var(--space-l);
    margin-top: var(--step-0);
    padding: 0;
}

.home p{
    font-size: var(--step-0);
    line-height: var(--space-m);
    transition: all 0.5s ease-in-out;
    color: rgb(256, 256, 256, 0.4);
    text-align: justify;
}

.toolslist{
    
    /*background-color: aqua;*/
    display: flex;
    flex-wrap: wrap;   
    width: 50vw;
    
}

.tools{
    height: 4vh;
    width: 6vw;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 30px;
    opacity: 0.98;
    background-color:#cd2e2e;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--space-m);
    margin-top: var(--space-s);

}

.tools p{
    font-size: var(--step--0);
    align-items: center;
    color: white;
    padding: 0;
    margin: 0;
    text-align: center;
}



@media (max-width: 1024px) {
    
    #home{
        
        height: 100vh; 
        background-color: rgb(0, 0, 0, 0.94);
        display: flex;
        justify-content:center;
        align-items:center;
        z-index: 1;
      }
      
    .home h1{
        font-size: var(--step-4);
        line-height: var(--space-xl);
        transition: all 0.5s ease-in-out;
        text-align: center;
        margin: 0;
    }
    
    .home h2{
        font-size: var(--step-2);
        color: rgb(256, 256, 256, 0.4);
        text-align: center;
        line-height: var(--space-l);
        margin-top: var(--step-0);
        padding: 0;
    }
    
    .home p{
        font-size: var(--step-0);
        line-height: var(--space-m);
        transition: all 0.5s ease-in-out;
        color: white;
        color: rgb(256, 256, 256, 0.4);
        text-align: center;
    }

    .homeimage{
        width: 0vw;
        height: 100vh;
        background-image: none;
    }

    .hometesto{
        width: 80%;
        height: 60vh;
        padding-bottom: var(--space-3xl);
        margin-left: 0;
        margin: 3vw;
    }

    .tools{
        height: 4vh;
        width: 12vw;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 30px;
        opacity: 0.98;
        background-color:#cd2e2e;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:2px;
        
        /*margin-right: var(--space-m);
        margin-top: var(--space-s);*/
        
    }

    .toolslist{
        height: 30vh;
        /*background-color: aqua;*/
        width: 100%;
        display: flex;
        flex-wrap: wrap;   
        justify-content: center;
        margin-top: var(--space-s);
        
    }

    .tools p{
        font-size: var(--step--0);
        align-items: center;
        color: white;
        padding: 0;
        margin: 0;
        
        text-align: center;
    }

   
}


