.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
}
.navbarprojects{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

/*CSS VARIABLES AND UTOPIA TYPOGRAPHY AND SPACING SYSTEM*/
:root {
  --step--2: clamp(0.78rem, 0.77rem + 0.03vw, 0.80rem);
  --step--1: clamp(0.94rem, 0.92rem + 0.11vw, 1.00rem);
  --step-0: clamp(1.13rem, 1.08rem + 0.22vw, 1.25rem);
  --step-1: clamp(1.35rem, 1.28rem + 0.37vw, 1.56rem);
  --step-2: clamp(1.62rem, 1.50rem + 0.58vw, 1.95rem);
  --step-3: clamp(1.94rem, 1.77rem + 0.87vw, 2.44rem);
  --step-4: clamp(2.33rem, 2.08rem + 1.25vw, 3.05rem);
  --step-5: clamp(2.80rem, 2.45rem + 1.77vw, 3.82rem);

  --space-3xs: clamp(0.31rem, 0.31rem + 0.00vw, 0.31rem);
  --space-2xs: clamp(0.56rem, 0.54rem + 0.11vw, 0.63rem);
  --space-xs: clamp(0.88rem, 0.85rem + 0.11vw, 0.94rem);
  --space-s: clamp(1.13rem, 1.08rem + 0.22vw, 1.25rem);
  --space-m: clamp(1.69rem, 1.62rem + 0.33vw, 1.88rem);
  --space-l: clamp(2.25rem, 2.16rem + 0.43vw, 2.50rem);
  --space-xl: clamp(3.38rem, 3.24rem + 0.65vw, 3.75rem);
  --space-2xl: clamp(4.50rem, 4.33rem + 0.87vw, 5.00rem);
  --space-3xl: clamp(6.75rem, 6.49rem + 1.30vw, 7.50rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.31rem, 0.20rem + 0.54vw, 0.63rem);
  --space-2xs-xs: clamp(0.56rem, 0.43rem + 0.65vw, 0.94rem);
  --space-xs-s: clamp(0.88rem, 0.74rem + 0.65vw, 1.25rem);
  --space-s-m: clamp(1.13rem, 0.86rem + 1.30vw, 1.88rem);
  --space-m-l: clamp(1.69rem, 1.40rem + 1.41vw, 2.50rem);
  --space-l-xl: clamp(2.25rem, 1.73rem + 2.61vw, 3.75rem);
  --space-xl-2xl: clamp(3.38rem, 2.81rem + 2.83vw, 5.00rem);
  --space-2xl-3xl: clamp(4.50rem, 3.46rem + 5.22vw, 7.50rem);

  --font_family: "Roboto", sans-serif;
  --bg_color: black;
  --bg_color_dark: #F5F5F5;
  --text_color: white;
  --primary_color: #22A473;
  --error_color: #ff3333;
}


/* Stili di base per h1 */
h1 {
  font-size: var(--step-4);
  margin-left: var(--space-l);
  margin-right:var(--space-l); 
  /*font-size: 60pt;*/
  line-height: 65pt;
  letter-spacing: normal;
  font-weight: bold;
  color: #1e1e1e; /* Nero con opacità al 50% */
  z-index: 1;
  
}

h2 {
  font-size: var(--step-3);
  padding-bottom: var(--space-m);
  line-height: 30pt;
  letter-spacing: normal;
  font-weight: bold;
  color: #1e1e1e; /* Nero con opacità al 50% */
  z-index: 1;
  transition: all 0.5s ease-in-out;
}

#about{
  height: 200vh; 
  background-color: rgb(256, 256, 256, 0.9);
  display: flex;
  justify-content: center;
  align-items: center; 
}

.home h1{
  color:white;
}

.contact{
  position: absolute;
  width: 100vw;
  max-width: 100%;
  padding-top: 20vh;
  padding-bottom: 20vh;
  height: auto;
  background-image: url('./components/images/cardboard-flat.png'); /* Immagine con effetto satinato */
  opacity: 0.1;
  z-index: -1;
  /* Opacità dell'immagine di sfondo */
   /* Posiziona dietro il contenuto principale */
}

#contact{
  max-width: 100vw;
  height: auto;
  background-color: rgb(256, 256, 256, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index:2;
}

.calendly-inline-widget {
  width: 80%;
  max-width: 100vw;
  height:100%;
  min-height: 130vh; /* Assicurati di avere un'altezza minima per una buona visualizzazione */
}
/*
.video-background {
  position: relative;
  height: 100vh;
  overflow: hidden;
  max-width: 100vw;
  
}

#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 0;
}

.videocardcontainer{
position:absolute;
width: 25vw;
max-width: 25vw;

}

.aboutcontainer{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Crea tre colonne di larghezza uguale 
}

.grid-item {
  background-color: rgb(256, 256, 256, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}*/

.testocontact{
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 80vw;
  padding-bottom: var(--step-2);
  transition: all 0.5s ease-in-out;
}

.testocontact p{
  font-size: var(--step-0);
    line-height: var(--space-m);
    color: rgb(0, 0, 0, 0.8);
    text-align: justify;
}

@media (max-width: 1024px) {
  .contact {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  #contact {
    padding-top: 0;
    padding-bottom: 0;
  }
  .calendly-inline-widget {
    min-height: 80vh;
  }
  .testocontact {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}


@media (max-width: 768px) {

 /* .contact{
    position: absolute;

    width: 100vw;
    max-width: 100%;
    padding-top: 0vh;
    padding-bottom: 0vh;
    height: 170vh;
    background-image: url('./components/images/cardboard-flat.png'); /* Immagine con effetto satinato 
    opacity: 0.1;
    z-index: -1;
    /* Opacità dell'immagine di sfondo 
    /* Posiziona dietro il contenuto principale 
    /* Opacità dell'immagine di sfondo 
    /* Posiziona dietro il contenuto principale 
  }*/
/*
  #contact{
    padding-top: 0vh;
    padding-bottom: 0vh;
    max-width: 100vw;
    height: 170vh;
    background-color: rgb(256, 256, 256, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index:2;
  }
  
  .calendly-inline-widget {
    width: 90vw;
    max-width: 100vw;
    height: 100%;
    min-height: 100vh; /* Assicurati di avere un'altezza minima per una buona visualizzazione 
    
  }
 
  .testocontact{
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 80vw;
    padding-bottom: var(--step-2);
  }
*/
.contact {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
#contact {
  padding-top: 0;
  padding-bottom: 0;
}
.calendly-inline-widget {
  min-height: 100vh;
}
.testocontact {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

}
