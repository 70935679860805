.projects{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
}

.projects h1{
    position:absolute;
    margin-top: 25vh;
}
.soon{
    width: 80vw; /* Specifica la dimensione del cerchio */
    height:80vh;
    overflow: hidden;
    background-size: contain;
    background-image: url('../images/dog.gif');
    background-position: center;
    background-repeat: no-repeat;
}