.bottom-ba {
  background-color: white;
  color: black;
  padding: 1rem;
  margin:0;
  text-align: center;
  position: relative;
  
  max-width: 100vw;
  bottom: 0;
}

  /*FOOTER RELATED CODE*/
  footer {
    width: 100%;
    height: fit-content;
    background-color:rgb(256, 256, 256);
    color: black;
    /*padding: 10px; assolutamente da non mettere*/
    position: relative;
    text-align: center;
    bottom: 0;
    /*opacity: 0.9;*/

}

/*.bottom-bar{
  position: absolute;
  width: 100vw;
  max-width: 100%;
  background-image: url('./images/cardboard-flat.png'); /* Immagine con effetto satinato 
  opacity: 1;
  z-index: -1;
  /* Opacità dell'immagine di sfondo
   /* Posiziona dietro il contenuto principale
}*/

.media-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-icons a {
    position: relative;
    color: var(--bg_color);
    font-size: 25px;
    transition: 0.3s;
    transition-property: transform;
}

.media-icons a:not(:last-child) {
    margin-right: 60px;
}

.media-icons a:hover {
    transform: scale(1.5);
}

.footer_icon {
    fill: black;
    width: 25px;
}


.footer_buttons {
    padding: 2vh;
    /*padding-bottom: 2vh;*/
    /*padding-left: 30vh;*/
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.footer_buttons a {
    padding-left: 2vh;
    padding-right: 2vh;
    position: left;
    justify-content: center;
    color: black;
    text-decoration: none;
    font-size: var(--step--2);
    font-weight: 300;
    letter-spacing: 1px;
    /*padding: 2px 10px ;*/
    border-radius: 20px;
    /*transition-property: background;*/
}

.footer_buttons a:hover {
    text-decoration: underline var(--bg_color) 0.10rem;
    text-underline-offset: 0.2rem;
}

.footer_line {
    height: 1vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /*margin-top: var(--space-2xs-xs);*/
    padding-bottom: var(--space-s-m);
}

.footer_content p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: var(--step--2);
    /*margin-right: 4vh;*/
    margin: 0;
    padding-top: var(--space-s-m);
    padding-right: var(--space-m-l);
    padding-bottom: var(--space-s-m);

}

/*MEDIA-QUERIES*/

@media (max-width:700px) {

    header {
        height: 6vh;
        padding-left: var(--space-xs-s);
        padding-right:var(--space-xs-s);
        padding-bottom: var(--space-s-m);
        justify-content: center;
    }

    header .logo {
        display: none;
        width: 0%;
        
    }

    section, .text_container {
        text-align: center;
        
    }

    .navbar {
        width: 80vw;
        display: block;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        font-size: var(--step--1);

    }

    .navbar_items {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .sub_paragraph_flex {
        gap: 0rem;
        flex-direction: column;
    }

    .small_width_p {
        max-width: 70ch;
    }

    .flex_row {
        padding: var(--space-s);
        gap: 0;
    }

    .flex_reverse_row {
        flex-direction: column-reverse;
    }

    .valori {
        text-align: center;
        margin-top: var(--space-s-m);
    }

    .elenco_valori {
        display: grid;
        justify-content: center;
        align-items: start;
        color: var(--bg_color);
        grid-template-columns: repeat(1, 1fr);
        margin: var(--space-s);
        margin-bottom: 5vh;
        gap: var(--space-s);
    }

   .footer_content {
       flex-direction: column;
   }

   .footer_content p {
    padding: 0;
    padding-inline: 0;
    padding-top: var(--space-xs-s);
    padding-bottom: var(--space-s-m);
   }
    
}

@media (max-width:1280px) {
    .flex_row {
        display: block;
    }

    .image,
    .image_container {
        display: none;
    }
}

.policy{
    display: flex;
    justify-content: center;
    width: 100%;
    padding:10vw;
}

.terms{
    display: flex;
    flex-direction: column;
    padding: 10vw;
}
.terms li {
    font-size: var(--step--0);
    line-height: 1.5;
    margin-bottom: var(--space-s-m);
    /*IMPORTANTE: massima lunghezza riga per best-practice UX!*/
    max-width: 70ch;
}

video::-webkit-media-controls {
    display: none;
  }
