.navbar {
  width: 100vw;
  background-color:rgb(0, 0, 0, 0.7);
  position: fixed;
  max-width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/*
.navbar ::before {
  width: 100;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/src/components/images/cardboard-flat.png'); /* Immagine con effetto satinato 
  opacity: 0.1; /* Opacità dell'immagine di sfondo 
  z-index: -1; /* Posiziona dietro il contenuto principale 
}*/

.navbar ul {
  width: 25%;
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .navbar ul{width: 35%;}
}

.navbar li {
  display: flex;
  justify-content: space-between;
}

.navbar a {
  color: rgb(256, 256, 256);
  text-decoration: none;
  font-weight:600;
  cursor: pointer;
}

.navbar a:hover {
  text-decoration: underline;
}

.content {
  margin-top: 60px; /* per evitare che il contenuto sia nascosto sotto la navbar */
}

.navbar.scrolled {
  background-color: #ffffff;
  /*box-shadow: 0 4px 2px -2px rgb(255, 255, 255);*/
}
.navbar.navbar.scrolled a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-weight:600;
  cursor: pointer;
}


.navbarprojects{
  width: 100vw;
  background-color:rgb(256, 256, 256, 0.7);
  position: fixed;
  max-width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


/*.navbarprojects ::before {
  width: 100;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/src/components/images/cardboard-flat.png'); /* Immagine con effetto satinato 
  opacity: 0.1; /* Opacità dell'immagine di sfondo 
  z-index: -1; /* Posiziona dietro il contenuto principale 
}*/

.navbarprojects ul {
  width: 25%;
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .navbarprojects ul{width: 35%;}
}

.navbarprojects li {
  display: flex;
  justify-content: space-between;
}

.navbarprojects a {
  color: black;
  text-decoration: none;
  font-weight:600;
  cursor: pointer;
}

.navbarprojects a:hover {
  text-decoration: underline;
}